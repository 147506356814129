import request from "../request";

export function personalEntry() {
  return request({
    url: "/user/personalEntry",
    method: "GET"
  });
}


