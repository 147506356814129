
<template>
  <div id="myWorks">
    <div class="myWorks-container">
      <div class="bread-title">
        <div class="list-name">个人中心</div>
      </div>
      <div class="profile">
        <div class="headimg" :style="{ backgroundImage: 'url(' + profile.headImg + ')' }" alt=""></div>
        <p>{{ profile.nickname }}</p>
        <div class="edit-btn" @click="editProfile">编辑资料</div>
      </div>
      <div class="works-box">
        <div class="works-item" :key="index" v-for="(item, index) in list.myList">
          <div class="works-item-cover" @click="goDetail(item)" :style="{ backgroundImage: 'url(' + item.cover + ')' }">
          </div>
          <div class="works-item-bottom">
            <div class="work-item-name">{{ item.name }}</div>
            <div class="work-item-autor-box" :class="[
              item.status === 0
                ? 'review'
                : item.status === 2
                  ? 'fail'
                  : 'success',
            ]">
              <p class="status">
                {{
                  item.status === 0
                  ? "正在审核"
                  : item.status === 2
                    ? "审核失败"
                    : "审核完成"
                }}</p>
              <el-tooltip class="tip" effect="dark" placement="top" v-if="item.status === 0">
                <template #content>
                  <div class="mytip">
                    <div>笨球快马加鞭审核中！请耐</div>
                    <div>心等待！</div>
                    <div>人工审核时间：周一至周五</div>
                    <div>8：30-17：30</div>
                  </div>
                </template>
                <i class="el-icon-question tipIcon"></i>
              </el-tooltip>
              <el-tooltip class="tip" effect="dark" placement="top" v-if="item.status === 2">
                <template #content>
                  <div class="mytip">
                    <div>您的投稿违反大赛相关规</div>
                    <div>定，请先阅读《投稿须</div>
                    <div>知》</div>
                  </div>
                </template>
                <i class="el-icon-question tipIcon"></i>
              </el-tooltip>
              <div class="edit-work-btn" @click="goEdit(item)">
                <img src="@/assets/img/edit-icon.png" alt="">
                <span>编辑</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="edit-profile">
      <el-dialog v-model="dialogVisible" @close="dialogClose">
        <p class="title">编辑资料</p>
        <el-form label-position="right">
          <el-form-item class="avator-form-item" label="头像">
            <div class="edit-headimg" :style="{ backgroundImage: 'url(' + profileinfo.headImg + ')' }"></div>
            <p class="tip">支持JPG/PNG格式，5M以内</p>
            <el-upload ref="coverFile" class="avator-upload" :show-file-list="false" :limit="1"
              accept="image/gif,image/jpeg,image/x-png" :on-change="(file) => uploadAli(file)" :auto-upload="false">
              <div class="upload-avator-btn">上传头像</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input v-model="profileinfo.name" placeholder="仅限中英文，10个字以内" class="input-item"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio-group v-model="profileinfo.gender">
              <el-radio :label="3">男</el-radio>
              <el-radio :label="6">女</el-radio>
              <el-radio :label="9">保密</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="生日">
            <el-date-picker v-model="profileinfo.birthday" type="date" placeholder="Pick a day"
              :disabled-date="disabledDate" :shortcuts="shortcuts" :size="size" />
          </el-form-item>
          <el-form-item label="城市">
            <el-cascader v-model="profileinfo.city" :options="cityoptions" clearable />
          </el-form-item>
          <el-form-item label="手机">
            <el-input v-model="profileinfo.phone" placeholder="仅限中英文，10个字以内" class="input-item"></el-input>
          </el-form-item>
          <el-form-item label="标签">
            <div class="taglist-wrap">
              <div v-for="(item, i) in tags.tagList"
                :class="[profileinfo.tagList.indexOf(item) !== -1 ? 'tagactive' : '', 'tag-item']" :key="item"
                @click="toogleTag(item)">
                {{ item }}
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="submit-profile-btn" @click="submitProfile">确认保存</div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { reactive, ref, watch, toRaw } from "vue";
import { personalEntry } from "../utils/apis/myWorkApi";
import { getPersonal, edit } from '../utils/apis/userApi'
import { getOssSignature } from "../utils/apis/fileApi";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { upload } from "../utils/uploadSign";
import citys from "../assets/json/cities.json"

export default {
  name: "myWorks",
  setup() {
    const coverFile = ref(null)
    const pageInfo = reactive({
      pageSize: 12,
      pageNum: 1,
      total: 13,
    });
    const profile = reactive({
      headImg: '',
      nickname: ''
    })
    const list = reactive({
      myList: [],
    });
    const profileinfo = reactive({
      phone: '',
      name: '',
      headImg: '',
      gender: 3,
      birthday: '',
      city: '',
      tagList: []
    })
    const mysign = reactive({
      data: null,
    });
    const cityoptions = reactive(citys)
    const tags = reactive({
      tagList: [
        '设计狮', '插画狮', '动画狮', '特效狮', '原画狮', '艺术家', '爱好者', '其他'
      ]
    })
    const name = ref("");
    const router = useRouter();
    const coverImage = ref(
      "https://cyc-test.oss-cn-shanghai.aliyuncs.com/design-competition/2021-04-23/16191407496701618967035552.jpg"
    );
    const dialogVisible = ref(false);
    const changed = ref(false)

    getSignature();
    getPersonlinfo();
    getList();
    watch(profileinfo, () => {
      changed.value = true
    })
    function getPersonlinfo() {
      getPersonal().then(res => {
        if (res.data.code === 200) {
          profile.headImg = res.data.data.headImg
          profile.nickname = res.data.data.name
          profileinfo.headImg = res.data.data.headImg
          profileinfo.name = res.data.data.name
          profileinfo.phone = res.data.data.phone
          profileinfo.gender = parseInt(res.data.data.gender)
          profileinfo.birthday = res.data.data.birthday
          profileinfo.city = res.data.data.city || []
          profileinfo.tagList = res.data.data.tagList || []
          changed.value = false
        }
      })
    }
    function dialogClose() {
      getPersonlinfo()
      coverFile.value.clearFiles();
    }
    function getList() {
      personalEntry().then((res) => {
        if (res.data.code === 200) {
          list.myList.push(...res.data.data)
        }
      });
    }
    function handleEdit() {
      edit(JSON.stringify(profileinfo)).then((res) => {
        if (res.data.code === 200) {
          dialogVisible.value = false
          ElMessage({
            message: `修改成功！`,
            type: "success",
            customClass: "myElMessage",
          });
          setTimeout(() => {
            location.reload()
          }, 1000);
        }
      })
    }
    function goDetail(item) {
      if (item.category == 1 || item.category === 3 || item.category === null) {
        router.push({
          path: "/myWorks/imageWorkDetail",
          query: {
            id: item.id,
            type: item.competitionUnit,
            views: 0,
          },
        });
      } else {
        router.push({
          path: "/myWorks/videoWorkDetail",
          query: {
            id: item.id,
            type: item.competitionUnit,
            views: 0,
          },
        });
      }
    }

    function editProfile() {
      dialogVisible.value = true
    }

    function getSignature() {
      //获取签名
      getOssSignature().then((res) => {
        const sign = res.data.data;
        mysign.data = sign;
      });
    }
    function uploadAli(file) {
      console.log(file)
      const params = new FormData();
      params.append(
        "key",
        mysign.data.dir +
        `${dateFormat("yyyy-MM-dd")}/` +
        `${randomString()}${getSuffix(file.name)}`
      );
      params.append("OSSAccessKeyId", mysign.data.accessKeyId);
      params.append("policy", mysign.data.policy);
      params.append("signature", mysign.data.signature);
      params.append("callback", mysign.data.callback);
      params.append("success_action_status", "200"); // 上传成功返回的状态码，不设置则是204
      params.append("file", file.raw); // 一定在最后
      if (mysign.data.expiration < Date.now() + 60 * 1000) {
        getSignature();
      }
      upload(params, mysign.data.host).then(res => {
        if (res.data.code === 200) {
          profileinfo.headImg = res.data.data.url
        }
      })
    }

    function dateFormat(fmt) {
      let getDate = new Date();
      let o = {
        "M+": getDate.getMonth() + 1,
        "d+": getDate.getDate(),
        "h+": getDate.getHours(),
        "m+": getDate.getMinutes(),
        "s+": getDate.getSeconds(),
        "q+": Math.floor((getDate.getMonth() + 3) / 3),
        S: getDate.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (getDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      if (fmt.includes("NaN")) {
        return "请刷新页面后重试";
      }
      return fmt;
    }

    function randomString(len) {
      len = len || 16;
      let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678";
      let maxPos = chars.length;
      let str = "";
      for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return str; sign
    }

    function getSuffix(filename) {
      let pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos !== -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    }
    function toogleTag(tag) {
      let index = profileinfo.tagList.indexOf(tag)
      if (index === -1) {
        profileinfo.tagList.push(tag)
      } else {
        profileinfo.tagList.splice(index, 1)
      }
    }
    function submitProfile() {
      if (!changed.value) {
        ElMessage({
          message: `请更改后再提交！`,
          type: "error",
          customClass: "myElMessage",
        });
      } else {
        handleEdit()
      }
    }
    function goEdit(item) {
      router.push(`/uploadfile?id=${item.id}`)
    }
    return {
      profile,
      pageInfo,
      coverImage,
      getList,
      list,
      name,
      goDetail,
      dialogVisible,
      editProfile,
      profileinfo,
      uploadAli,
      cityoptions,
      tags,
      toogleTag,
      submitProfile,
      changed,
      goEdit,
      dialogClose,
      coverFile
    };
  },
};
</script>

<style lang="less" scoped>
#myWorks {
  background-color: #f7f7f7;

  .myWorks-container {
    width: 1030px;
    margin: 0 auto;
    padding-top: 150px;
  }


  .list-name {
    font-size: 50px;
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    text-align: center;
    color: #222222;
    overflow: hidden;
  }

  .profile {
    margin-top: 64px;
    height: 80px;
    position: relative;

    .headimg {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      background-color: #F4F4F4;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &>p {
      position: absolute;
      left: 95px;
      bottom: 39px;
      font-size: 24px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 500;
      text-align: center;
      color: #333333;
    }

    .edit-btn {
      position: absolute;
      left: 95px;
      bottom: 7px;
      width: 70px;
      height: 24px;
      font-size: 14px;
      color: #fff;
      background-color: #1A6CB0;
      border-radius: 4px;
      text-align: center;
      line-height: 24px;
      cursor: pointer;
    }
  }

  .works-box {
    padding-top: 53px;
    min-height: 300px;
    margin-left: -24px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 300px;

    .works-item {
      margin-left: 18px;
      width: 244px;
      height: 260px;
      margin-bottom: 20px;
      background-color: #F2F2F2;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid #fff;
      box-sizing: border-box;

      .works-item-cover {
        width: 244px;
        height: 180px;
        background-size: cover;
        cursor: pointer;
      }
    }

    .works-item-bottom {
      height: 80px;
      padding: 12px 0px;
      box-sizing: border-box;

      .work-item-name {
        margin-left: 17px;
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN-Medium;
        font-weight: 500;
        color: #222222;
      }

      .work-item-autor-box {
        margin-top: 10px;
        margin-left: 17px;
        position: relative;

        .status {
          display: inline-block;
          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          font-weight: 400;
          color: #77797e;
        }

        .tip {
          display: inline-block;
        }

        .edit-work-btn {
          width: 58px;
          height: 22px;
          border-radius: 2px;
          background-color: #1A6CB0;
          position: absolute;
          right: 14px;
          bottom: 0px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          &>img {
            width: 13px;
            height: 13px;
            margin-right: 3px;
          }

          &>span {
            font-size: 14px;
            color: #ffffff;

          }
        }
      }
    }

    .tip {
      margin-left: 9px;
      font-size: 20px;
      vertical-align: middle;
    }
  }

  .pagination {
    text-align: center;
    margin-top: 25px;
    padding-bottom: 85px;
  }

  .edit-profile {
    ::v-deep .el-dialog {
      box-sizing: border-box;
      width: 610px !important;
      height: 751px;
      border-radius: 4px;
      background-color: #F7F7F7;
      padding-left: 106px;

      .title {
        font-size: 24px;
        font-weight: bold;
        margin-left: 151px;
        margin-bottom: 40px;
      }

      .el-form-item__label {
        padding: 0 19px 0 0;
      }

      .avator-form-item {
        .el-form-item__label {
          line-height: 80px;
        }

        .edit-headimg {
          width: 80px;
          height: 80px;
          border-radius: 80px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        .tip {
          position: absolute;
          left: 87.7px;
          top: 0px;
        }

        .avator-upload {
          position: absolute;
          border-radius: 4px;
          left: 87.7px;
          bottom: 10px;

          .upload-avator-btn {
            width: 80px;
            height: 30px;
            border-radius: 4px;
            background-color: #325888;
            text-align: center;
            line-height: 30px;
            color: #fff;
          }
        }
      }

      .input-item {
        width: 300px;
        position: relative;
      }

      .taglist-wrap {
        width: 330px;

        .tag-item {
          width: 70px;
          float: left;
          height: 34px;
          line-height: 34px;
          color: rgba(0, 0, 0, .3);
          border: 1px solid #cccccc;
          text-align: center;
          border-radius: 4px;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .tagactive {
          border: 1px solid #325888;
        }
      }
    }

    .submit-profile-btn {
      width: 152px;
      height: 40px;
      line-height: 40px;
      margin-left: 123px;
      background-color: #325888;
      border-radius: 4px;
      cursor: pointer;
      color: #fff;
      text-align: center;
    }
  }

  .pagination /deep/ .el-pager li {
    background-color: #f7f7f7;
  }

  .pagination /deep/ .el-pager li.active {
    color: #2397ff;
  }

  .pagination /deep/ .el-pagination .btn-next,
  .pagination /deep/ .el-pagination .btn-prev {
    background-color: #f7f7f7;
  }

  ::v-deep .el-input {
    width: 300px;
  }


  .review .tip {
    color: #d4d4d4;
  }

  .fail .tip {
    color: #e40000;
  }


}
</style>
