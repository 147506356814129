import axios from "axios";

import { ElMessage } from "element-plus";
// let instance = axios.create();


export const upload = async (parmas, url) => {
    const serve = axios.create({
        baseURL: url,
    })
    return new Promise((resolve, reject) => {
        serve({
            method: "POST",
            url: url,
            data: parmas,

        }).then(res => {
            if (res.status === 200) {
                resolve(res)
            } else {
                ElMessage({
                    message: "上传失败，请重试",
                    type: "error",
                    customClass: "myElMessage",
                });
                // reject(error)
                reject(res)
            }
        }).catch(error => {
            console.log(error);
            ElMessage({
                message: "上传超时",
                type: "error",
                customClass: "myElMessage",
            });
        })
    })


}