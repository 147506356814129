import request from '../request'

export function getPersonal() {
  return request({
    url: '/user/getPersonal',
    method: 'GET'
  })
}

export function edit(parmas) {
  return request({
    url: '/user/edit',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: parmas
  })
}

export function submitReport(parmas) {
  return request({
    url: '/report/submitReport',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: parmas
  })
}

export function markView() {
  return request({
    url: '/statistics/markView',
    method: 'POST'
  })
}
export function addView(params) {
  return request({
    url: '/statistics/addView',
    method: 'POST',
    params
  })
}