import request from "../request.js";
// export function example(params) {
//   return request({
//     url: "",
//     method: "get",
//     params,
//   });
// }

// async judgeImg(url) {
//     let { data } = await axios.get(url);
//     return data;
// },
export function judgeImg(url) {
  return request({
    url: url,
    method: "get",
  });
}

//上传
export function create(params) {
  return request({
    url: "/entry/create",
    method: "POST",
    data: params,
  });
}
//
export function listAll() {
  return request({
    url: "/recommender/listAll",
    method: "GET",
  });
}

///
export function getOssSignature() {
  return request({
    url: "/file/getOssSignature",
    method: "GET",
  });
}

export function edit(params) {
  return request({
    url: "/entry/edit",
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  });
}

export function downloadFileToServer(data) {
  return request({
    url: "/entry/downloadFileToServer",
    method: "POST",
    data
  })
}